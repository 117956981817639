.Header {
  top: 0px;
  display: flex !important;
  left: 0px !important;
  right: 0px !important;
}

.header-outer-container {
  padding: 8px;
  width: 100%;
  height: 42px;
  text-align: center !important;
}

.header-outer-container-mobile {
  padding: 0px 8px 0px 8px;
  width: 100%;
  height: 42px;
  text-align: center !important;
}

.header-inner-container {
  margin: 0px !important;
  word-wrap: break-word !important;
  font-size: 12px;
  font-weight: 400;
  display: flex;
  line-height: 1.3333333333333333em !important;
  margin: auto;
  width: 100%;
}

.menu-button-container {
  border-radius: 6px;
  height: 33px;
  width: 33px;
  float: right;
}

.menu-button {
  margin-top: 3px;
  width: 28px !important;
  height: 28px !important;
}

.header-inner-left {
  margin-top: 4px;
  width: 200px;
}

.header-inner-center {
  margin-top: 4px;
  flex-grow: 1;
}

.header-inner-right {
  width: 200px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.header-logo {
  width: 16px;
  height: 16px;
  vertical-align: text-bottom;
}

.header-strong {
  overflow-wrap: break-word !important;

  font-size: inherit !important;
  font-weight: 600 !important;
  line-height: 1.44444em !important;
  color: #484848;
  margin: 0px !important;
}

.header-text {
  overflow-wrap: break-word !important;
  text-decoration: none;
  font-size: 15px;
  font-weight: 600 !important;
  line-height: 1.44444em !important;
}

.link-text {
  overflow-wrap: break-word !important;
  text-decoration: none;
  font-size: 15px;
  font-weight: 600 !important;
  line-height: 1.44444em !important;
}

.link-text:hover {
  cursor: pointer;
  text-decoration: none;
  color: #008489 !important;
}

.link-text-custom {
  overflow-wrap: break-word !important;
  text-decoration: none;
  font-size: 15px;
  font-weight: 600 !important;
  line-height: 1.44444em !important;
}

.link-text-custom:hover {
  color: var(--header--hover-color) !important;
  cursor: pointer;
  text-decoration: none;
}

.language-selector-container {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-right: 10px;
  height: 100%;
}

.language-selector {
  overflow-wrap: break-word !important;
  text-decoration: none;
  font-size: 14px;
  font-weight: 600 !important;
  line-height: 1.44444em !important;
  cursor: pointer;
  padding: 5px;
}

.language-selector:hover {
  color: var(--header--hover-color, #008489) !important;
  text-decoration: none;
}

.language-selector-active {
  font-weight: 700 !important;
}

@media only screen and (max-width: 400px) {
  .link-text-custom {
    font-size: 80%;
  }

  .header-text {
    font-size: 80%;
  }

  .language-selector {
    font-size: 12px;
  }
}


;